import { ServiceFactory } from '@/services/ServiceFactory'
const _discountsService = ServiceFactory.get('DiscountsService')
export default {
  name: 'ZbCompaniesTabsComponent',
  data () {
    return {
      items: [
        {
          day: 'Lunes',
          value: 0
        },
        {
          day: 'Martes',
          value: 1
        },
        {
          day: 'Miercoles',
          value: 2
        },
        {
          day: 'Jueves',
          value: 3
        },
        {
          day: 'Viernes',
          value: 4
        },
        {
          day: 'Sabado',
          value: 5
        },
        {
          day: 'Domingo',
          value: 6
        }
      ],
      people_items: [
        {
          people: '1 persona',
          value: 1
        },
        {
          people: '2 personas',
          value: 2
        },
        {
          people: '3 personas',
          value: 3
        },
        {
          people: '4 personas',
          value: 4
        },
        {
          people: '5 personas',
          value: 5
        },
        {
          people: '6 personas',
          value: 6
        },
        {
          people: '7 personas',
          value: 7
        },
        {
          people: '8 personas',
          value: 8
        },
        {
          people: '9 personas',
          value: 9
        },
        {
          people: '10 personas',
          value: 10
        },
        {
          people: '11 personas',
          value: 11
        },
        {
          people: '12 personas',
          value: 12
        },
        {
          people: '13 personas',
          value: 13
        },
        {
          people: '14 personas',
          value: 14
        },
        {
          people: '15 personas',
          value: 15
        }
      ],
      hours: [
        '08:30',
        '09:00',
        '09:30',
        '10:00'
      ],
      value: 0,
      schedule: [],
      arraySchedule: [],
      sliderIcon: '',
      sliderlock: false,
      day: 0,
      people: 1,
      postSchedule: {
        day: 0,
        hour: '',
        discount: 0,
        people: 0
      }
    }
  },
  mounted () {
    this.currentDate()
    this.initialize()
    this.lockDiscount()
  },
  methods: {
    currentDate () {
      // calculate current day
      const date = new Date()
      const currentDay = date.getDay()
      // console.log(currentDay)
      if (currentDay === 0) {
        this.day = 6
      } else {
        this.day = currentDay - 1
      }
    },
    async setNumberPeople () {
      try {
        const getSchedule = await _discountsService.show(this.$route.params.id, this.day)
        console.log(getSchedule)
        if (getSchedule.schedule[0]) {
          this.people = getSchedule.schedule[0].people
        }
        // this.people = getSchedule.schedule[0].people
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: 'Algo salió mal al consultar el numero de personas, vuelva a la lista de negocios e intente nuevamente'
        })
      }
    },
    async initialize () {
      this.setNumberPeople()
      // this.$router.push({ name: 'edit' })
      if (this.$route.params.id) {
        // const id = this.$route.params.id
        // console.log(this.$route.params.id)
        try {
          // console.log(this.day)
          this.schedule = await _discountsService.index(this.$route.params.id, this.day)
          console.log(this.schedule)
          await this.schedule.schedule.map((v, k) => {
            v.discount = v.discount * 100
            // console.log(v.discount)
          })
          // console.log(this.schedule)
          console.log(this.schedule)
        } catch (error) {
          console.log(error)
          console.log('error')
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al consultar los horarios de reservas, vuelva a la lista de negocios e intente nuevamente'
          })
        }
      } else {
        // console.log('no id')
        this.$router.push({ name: 'companies' })
      }
    },
    lockDiscount () {
      // console.log('lock unlock')
      if (this.sliderlock) {
        this.sliderlock = false
        this.sliderIcon = 'fas fa-lock-open'
      } else {
        this.sliderlock = true
        this.sliderIcon = 'fas fa-lock'
      }
    },
    async postDiscount (item) {
      // console.log(item)
      this.postSchedule.day = this.day
      this.postSchedule.hour = item.hour
      this.postSchedule.discount = item.discount / 100
      this.postSchedule.people = this.people
      console.log(this.postSchedule)
      if (this.$route.params.id) {
        // const id = this.$route.params.id
        // console.log(this.$route.params.id)
        try {
          const resp = await _discountsService.create(this.$route.params.id, this.postSchedule)
          // console.log(resp)
          this.$notify({
            type: 'success',
            text: 'Se guardo el descuento correctamente'
          })
        } catch (error) {
          this.initialize()
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al guardar los descuentos, inténtelo nuevamente'
          })
        }
      } else {
        // console.log('no id')
      }
    },
    async eventActive (item) {
      console.log(item.discount)
      this.$notify({
        type: 'success',
        text: 'Evento activado'
      })
    },
    async numberPeople () {
      // console.log(this.people)
      try {
        this.arraySchedule = await _discountsService.show(this.$route.params.id, this.day)
        await this.arraySchedule.schedule.map((v, k) => {
          v.people = this.people
          v.day = this.day
          // console.log(v.people)
        })
        // console.log(this.arraySchedule)
        const batch = {
          day: this.day,
          schedule: this.arraySchedule.schedule
        }
        console.log(batch)
        await _discountsService.edit(this.$route.params.id, batch)
        this.$notify({
          type: 'success',
          text: 'Se cambió el número de personas correctamente'
        })
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Algo salió mal al cambiar el numero de personas, intente nuevamente'
        })
      }
    },
    close () {
      this.$router.push({ path: 'companies' }).catch(() => {})
      // this.$router.push({ name: 'companies' })
    }
  }
}
